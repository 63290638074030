<template>
  <div id="pagePerfis">
    <v-container grid-list-x2 fluid>
      <v-layout row wrap justify-center>
        <v-flex lg9 sm12 xs12>
          <perfis-table></perfis-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import PerfisTable from "@/components/perfisAcesso/tablePerfisAcesso"
export default {
  components: {
    PerfisTable
  }
}
</script>
